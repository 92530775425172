<template>
  <b-modal id="promotion" title="" hide-footer size="lg">
    <div class="text-center">
      <!-- <h2 class="text-warning">Aproveite!!!</h2> -->
      <img
        class="mt-0 pt-0"
        height="300"
        src="@/assets/img/blackweek2022.png"
        alt=""
      />
      <h5>
        Estamos com descontos em todos os nossos planos!
        <br /><br />
        Plano anual Avançado de R$49,99 por R$40,00.
        <br />
        Plano Profissional de R$89,99 por R$60,00.
      </h5>
      <h6>Essa promoção só é válida até às 23:59 do dia 30/11/2022.</h6>
      <button class="btn btn-success btn-block" block @click="Promotion">
        Acessar
      </button>
    </div>
  </b-modal>
</template>

<script>
import firebase from "firebase/app";
export default {
  computed: {
    currentUser() {
      return this.$store.state.user.CurrentUser;
    },
    DataBase: {
      get: function () {
        return this.$store.state.portfolio.DataBase;
      },
      set: function (newValue) {
        this.$store.state.portfolio.DataBase = newValue;
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.DataBase.notification === undefined) {
        this.DataBase.notification = [];
        this.DataBase.notification.push({
          id: 0,
          view: false,
          delete: false,
          title: "Seja Bem Vindo!!!",
          text: "Estamos de cara, e tecnologia, novas, para melhorar a forma de realizar o seu rebalanceamento. Aproveite as novidades!<br><br> Qualquer dúvida estamos a disposição pelo e-mail: contato@carteiraholder.com.br",
          date: new Date().toLocaleDateString(),
        });
      }
      if (
        Date.parse(new Date()) >= Date.parse(new Date("2023-11-20")) &&
        Date.parse(new Date()) < Date.parse(new Date("2023-12-01"))
      ) {
        if (
          this.DataBase.notification.filter((item) => item.id == 7).length == 0
        ) {
          this.DataBase.notification.push({
            id: 7,
            view: false,
            delete: false,
            title: "Black Week!!!",
            text: `
                            <div>
                                <img class="mt-0 pt-0" height="200" src="https://api.carteiraholder.com.br/img/blackweek.jpg" alt="">
                            </div>
                            <h5>
                                Estamos com descontos em todos os nossos planos anuais!
                                <br><br>
                                Plano anual Avançado de R$49,99 por R$40,00.
                                <br>
                                Plano Profissional de R$89,99 por R$60,00.
                            </h5>
                            <h6>Essa promoção só é válida até às 23:59 do dia 30/11/2023.</h6>`,
            date: new Date().toLocaleDateString(),
          });
        }
        this.OpenPromotion();
      }

      

      if (
        this.DataBase.settings.viewTermsOfUse === undefined ||
        this.DataBase.settings.viewTermsOfUse == false
      ) {
        this.DataBase.notification.push({
          id: 4,
          view: false,
          delete: false,
          title: "Nossas políticas foram atualizadas.",
          text: `
                        Segue o link para acessar nossas novas políticas:
                        <br><br>
                        <ul>
                            <li class="listunorder">
                                <a href="https://carteiraholder.com.br/docs/Condicoes-e-Termos-de-Uso-Carteira-Holder.pdf" class="btn-footer btn btn-link" target="_blank">Condições e Termos de Uso</a> 
                            </li>
                            <li class="listunorder">
                                <a href="https://carteiraholder.com.br/docs/politica-de-protecao-aos-direitos-autorais.pdf" class="btn-footer btn btn-link" target="_blank">Política de Proteção aos Direitos Autorais</a>
                            </li>
                            <li class="listunorder">
                                <a href="https://carteiraholder.com.br/docs/Politica-de-Privacidade-LGPD.pdf" class="btn-footer btn btn-link" target="_blank">Política de Privacidade - LGPD</a>
                            </li>
                        </ul>
                        <br>
                        Elas também estarão disponíveis no rodapé de nossas páginas. Qualquer dúvida estamos a disposição pelo e-mail: contato@carteiraholder.com.br.
                        <br>
                        Ao continuar usando nossa plataforma, você está de acordo com as nossas novas políticas.
                        <br><br>
                        Atenciosamente.
                        <br>
                        Equipe Carteira Holder.

                    `,
          date: new Date().toLocaleDateString(),
        });
        this.DataBase.settings.viewTermsOfUse = true;
      }

      this.Save();
    }, 2000);
  },
  methods: {
    OpenPromotion() {
      this.$bvModal.show("promotion");
    },
    Promotion() {
      this.$router.push("pricing");
      this.$bvModal.hide("promotion");
    },
    Save() {
      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
